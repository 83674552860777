export const FirebaseConfig = {
	"projectId": "bn-taxi-anytime",
	"appId": "1:374102401313:web:957a9926bab1bd551bde60",
	"databaseURL": "https://bn-taxi-anytime-default-rtdb.firebaseio.com",
	"storageBucket": "bn-taxi-anytime.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCTK5Eea7HUi_QAGLbRy9cCq8N5UauFF7s",
	"authDomain": "bn-taxi-anytime.firebaseapp.com",
	"messagingSenderId": "374102401313",
	"measurementId": "G-VSZS1VKBG5"
};